import { User } from "firebase/auth";
import { useFirestore, useUser } from "reactfire";
import { Button } from "../components/Button";
import { SchemeData, SchemesList } from "../scheme/SchemesList";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { ArchivedList } from "../scheme/ArchivedList";

export const ArchivedPage = () => {
  return (
    <>
      <ArchivedList />
    </>
  );
};
