import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { Spinner } from "./components/Spinner";

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import {
  FirestoreProvider,
  useAuth,
  useInitFirestore,
  useSigninCheck,
  FirebaseAppProvider,
  AuthProvider,
  useFirebaseApp,
  FunctionsProvider,
} from "reactfire";
import { getAuth } from "firebase/auth";
import {
  initializeFirestore,
  enableIndexedDbPersistence,
} from "firebase/firestore";

import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Navigate,
} from "react-router-dom";

import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Button } from "./components/Button";
import { Root } from "./layout/Root";
import { AuthCheck, SignInForm } from "./pages/SignInPage";
import { ListsPage } from "./pages/ListsPage";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { ArchivedPage } from "./pages/ArchivedPage";
import LandingPage from "./pages/LandingPage";
import { SubscriptionPage } from "./pages/SubscriptionPage";
import { TermsOfServicePage } from "./pages/terms/TermsOfServicePage";
import { PrivacyPolicyPage } from "./pages/terms/PrivacyPolicyPage";
import NotFoundPage from "./pages/404Page";
import { InvitePage } from "./pages/InvitePage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCMPllF1w7hmnuVorXEgN-awrtiYifU2nU",
  authDomain: "autodo.to",
  projectId: "scheme-1337",
  storageBucket: "scheme-1337.appspot.com",
  messagingSenderId: "501103144868",
  appId: "1:501103144868:web:07b17f931765a829ad5b31",
  measurementId: "G-4CXCG2LNY9",
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <LandingPage />,
    errorElement: <NotFoundPage />,
  },
  {
    path: "/app",
    element: <Root />,
    errorElement: <NotFoundPage />,
    children: [
      { path: "/app", element: <Navigate to="/app/lists" replace={true} /> },
      {
        path: "lists",
        element: (
          <AuthCheck>
            <ListsPage />
          </AuthCheck>
        ),
      },
      {
        path: "archive",
        element: (
          <AuthCheck>
            <ArchivedPage />
          </AuthCheck>
        ),
      },
      {
        path: "join/:inviteCode",
        element: (
          <AuthCheck>
            <InvitePage />
          </AuthCheck>
        ),
      },
      // {
      //   path: "subscription",
      //   element: (
      //     <AuthCheck>
      //       <SubscriptionPage />
      //     </AuthCheck>
      //   ),
      // },
      {
        path: "tos",
        element: <TermsOfServicePage />,
      },
      {
        path: "privacy",
        element: <PrivacyPolicyPage />,
      },
    ],
  },
]);

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

export default function App() {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <FirebaseProviders>
        <RouterProvider router={router} />
      </FirebaseProviders>
    </FirebaseAppProvider>
  );
}

function FirebaseProviders({ children }: any) {
  const app = useFirebaseApp();

  const functions = getFunctions(app);

  if (process.env.NODE_ENV === "development") {
    connectFunctionsEmulator(functions, "localhost", 5001);
  }
  // initialize Database and Auth with the normal Firebase SDK functions
  const { status, data: firestoreInstance } = useInitFirestore(
    async (firebaseApp) => {
      const db = initializeFirestore(firebaseApp, {});
      // await enableIndexedDbPersistence(db);
      return db;
    }
  );

  const auth = getAuth(app);

  if (status === "loading") {
    return <Spinner />;
  }

  // any child components will be able to use `useUser`, `useDatabaseObjectData`, etc
  return (
    <AuthProvider sdk={auth}>
      <FunctionsProvider sdk={functions}>
        <FirestoreProvider sdk={firestoreInstance}>
          {children}
        </FirestoreProvider>
      </FunctionsProvider>
    </AuthProvider>
  );
}
