import {
  collection,
  doc,
  limit,
  orderBy,
  query,
  where,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  useFirestore,
  useFirestoreCollectionData,
  useFunctions,
  useUser,
} from "reactfire";
import { Button } from "../components/Button";
import { Spinner } from "../components/Spinner";
import { SchemeData } from "../scheme/SchemesList";

export const InvitePage = () => {
  const functions = useFunctions();
  const joinList = httpsCallable(functions, "joinList");
  const [loading, setLoading] = useState(false);

  const { inviteCode } = useParams();
  const navigate = useNavigate();

  return (
    <div className="flex min-h-full text-center align-middle items-center justify-center py-12 px-4 sm:px-6 lg:px-8 flex-col space-y-6">
      <div className="w-full max-w-md space-y-8">
        <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
          Join List
        </h2>
      </div>
      <div className="w-full max-w-md space-y-8">
        Click the button below to join this list. Other members of the list will
        be able to see your name and profile image.
      </div>
      <Button
        disabled={loading}
        onClick={async () => {
          setLoading(true);
          await joinList({
            inviteCode,
          });
          navigate("/app/lists");
        }}
      >
        {loading ? <Spinner /> : "Join List"}
      </Button>
    </div>
  );
};
