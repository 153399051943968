import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import { Fragment } from "react";
import { Disclosure, Menu, Transition } from "@headlessui/react";
import { Bars3Icon, BellIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { SchemesList } from "../scheme/SchemesList";
import { useAuth, useUser } from "reactfire";
import { signOut } from "firebase/auth";
import logo from "./logo.png";

const navigation = [
  { name: "Lists", href: "/app/lists" },
  { name: "Archive", href: "/app/archive" },
  // { name: "Subscription", href: "/app/subscription" },
  // { name: "Team", href: "#", current: false },
  // { name: "Projects", href: "#", current: false },
  // { name: "Calendar", href: "#", current: false },
  // { name: "Reports", href: "#", current: false },
];

function classNames(...classes: any[]) {
  return classes.filter(Boolean).join(" ");
}

export const Root = () => {
  const user = useUser();
  const auth = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  if (user.status === "loading") {
    return null;
  }

  return (
    <>
      {/*
  This example requires updating your template:

  ```
  <html class="h-full bg-gray-100">
  <body class="h-full">
  ```
*/}
      <div className="flex flex-col h-screen">
        <div className="pb-32 bg-gradient-to-r from-orange-400 to-pink-500">
          <Disclosure as="nav" className="bg-gray-800">
            {({ open }) => (
              <>
                <div className="mx-auto max-w-7xl sm:px-6 lg:px-8">
                  <div className="border-b border-gray-700">
                    <div className="flex h-16 items-center justify-between px-4 sm:px-0">
                      <div className="flex items-center">
                        <div className="flex-shrink-0">
                          {/* <img
                            className="h-8 w-8"
                            src="https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=500"
                            alt="Autotodo"
                          /> */}

                          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex flex-row">
                            <img src={logo} className="h-9 mr-2" />
                            <h1 className="text-3xl font-bold tracking-tight text-white">
                              Autodo
                            </h1>
                          </div>
                        </div>
                        <div className="hidden md:block">
                          <div className="ml-10 flex items-baseline space-x-4">
                            {navigation.map((item) => {
                              const current = location.pathname === item.href;
                              return (
                                <Link
                                  key={item.name}
                                  to={item.href}
                                  className={classNames(
                                    current
                                      ? "bg-gray-900 text-white"
                                      : "text-gray-300 hover:bg-gray-700 hover:text-white",
                                    "px-3 py-2 rounded-md text-sm font-medium"
                                  )}
                                  aria-current={current ? "page" : undefined}
                                >
                                  {item.name}
                                </Link>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                      <div className="hidden md:block">
                        <div className="ml-4 flex items-center md:ml-6">
                          {/* <button
                            type="button"
                            className="rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                          >
                            <span className="sr-only">View notifications</span>
                            <BellIcon className="h-6 w-6" aria-hidden="true" />
                          </button> */}

                          {/* Profile dropdown */}
                          <Menu as="div" className="relative ml-3">
                            <div>
                              <Menu.Button className="flex max-w-xs items-center rounded-full bg-gray-800 text-sm focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                                <span className="sr-only">Open user menu</span>
                                {user?.data?.photoURL && (
                                  <img
                                    className="h-8 w-8 rounded-full"
                                    src={user.data.photoURL}
                                    alt=""
                                  />
                                )}
                              </Menu.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              enter="transition ease-out duration-100"
                              enterFrom="transform opacity-0 scale-95"
                              enterTo="transform opacity-100 scale-100"
                              leave="transition ease-in duration-75"
                              leaveFrom="transform opacity-100 scale-100"
                              leaveTo="transform opacity-0 scale-95"
                            >
                              <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                <Menu.Item>
                                  <a
                                    onClick={() => signOut(auth)}
                                    href={"#"}
                                    className={
                                      "block px-4 py-2 text-sm text-gray-700"
                                    }
                                  >
                                    Sign out
                                  </a>
                                </Menu.Item>
                              </Menu.Items>
                            </Transition>
                          </Menu>
                        </div>
                      </div>
                      <div className="-mr-2 flex md:hidden">
                        {/* Mobile menu button */}
                        <Disclosure.Button className="inline-flex items-center justify-center rounded-md bg-gray-800 p-2 text-gray-400 hover:bg-gray-700 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800">
                          <span className="sr-only">Open main menu</span>
                          {open ? (
                            <XMarkIcon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          ) : (
                            <Bars3Icon
                              className="block h-6 w-6"
                              aria-hidden="true"
                            />
                          )}
                        </Disclosure.Button>
                      </div>
                    </div>
                  </div>
                </div>

                <Disclosure.Panel className="border-b border-gray-700 md:hidden">
                  <div className="space-y-1 px-2 py-3 sm:px-3">
                    {navigation.map((item) => {
                      const current = location.pathname === item.href;
                      return (
                        <Disclosure.Button
                          key={item.name}
                          as="a"
                          onClick={() => navigate(item.href)}
                          className={classNames(
                            current
                              ? "bg-gray-900 text-white"
                              : "text-gray-300 hover:bg-gray-700 hover:text-white",
                            "block px-3 py-2 rounded-md text-base font-medium"
                          )}
                          aria-current={current ? "page" : undefined}
                        >
                          {item.name}
                        </Disclosure.Button>
                      );
                    })}
                  </div>
                  <div className="border-t border-gray-700 pt-4 pb-3">
                    <Disclosure.Button
                      as="a"
                      href="mailto:support@autodo.to"
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Contact
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/app/tos"
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Terms of Service
                    </Disclosure.Button>
                    <Disclosure.Button
                      as="a"
                      href="/app/privacy"
                      className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                    >
                      Privacy Policy
                    </Disclosure.Button>
                  </div>
                  <div className="border-t border-gray-700 pt-4 pb-3">
                    <div className="flex items-center px-5">
                      <div className="flex-shrink-0">
                        {user?.data?.photoURL && (
                          <img
                            className="h-10 w-10 rounded-full"
                            src={user.data.photoURL}
                            alt=""
                          />
                        )}
                      </div>
                      <div className="ml-3">
                        <div className="text-base font-medium leading-none text-white">
                          {user?.data?.displayName}
                        </div>
                        <div className="text-sm font-medium leading-none text-gray-400">
                          {user?.data?.email}
                        </div>
                      </div>
                      {/* <button
                        type="button"
                        className="ml-auto flex-shrink-0 rounded-full bg-gray-800 p-1 text-gray-400 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-gray-800"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button> */}
                    </div>
                    <div className="mt-3 space-y-1 px-2">
                      {auth.currentUser && (
                        <Disclosure.Button
                          onClick={() => signOut(auth)}
                          as="a"
                          href="#"
                          className="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
                        >
                          Sign out
                        </Disclosure.Button>
                      )}
                    </div>
                  </div>
                </Disclosure.Panel>
              </>
            )}
          </Disclosure>
          {/* <header className="py-10">
            <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
              <h1 className="text-3xl font-bold tracking-tight text-white">
                Lists
              </h1>
            </div>
          </header> */}
        </div>

        <main className="-mt-24 mb-auto">
          <div className="mx-auto max-w-7xl px-4 pb-12 sm:px-6 lg:px-8">
            {/* Replace with your content */}
            <div className="rounded-lg bg-white px-5 py-6 shadow sm:px-6">
              <Outlet />
            </div>

            {/* <SignInPage /> */}
            {/* /End replace */}
          </div>
        </main>

        <footer className="bg-white shadow-inner hidden md:block">
          <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
            <nav
              className="-mx-5 -my-2 flex flex-wrap justify-center"
              aria-label="Footer"
            >
              <div className="px-5 py-2">
                <a
                  href="mailto:support@autodo.to"
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  Contact
                </a>
              </div>
              <div className="px-5 py-2">
                <a
                  href="/app/tos"
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  Terms of Service
                </a>
              </div>
              <div className="px-5 py-2">
                <a
                  href="/app/privacy"
                  className="text-base text-gray-500 hover:text-gray-900"
                >
                  Privacy Policy
                </a>
              </div>
            </nav>
            {/* <div className="mt-8 flex justify-center space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div> */}
          </div>
        </footer>
      </div>
    </>
  );
};
