import Markdown from "markdown-to-jsx";
import raw from "raw.macro";
import React from "react";

export const PrivacyPolicyPage: React.FC = (props) => {
  return (
    <div className="leading-7">
      <Markdown>{raw("./privacy.md")}</Markdown>
    </div>
  );
};
