import { PlusIcon } from "@heroicons/react/20/solid";
import {
  collection,
  doc,
  FieldValue,
  limit,
  orderBy,
  query,
  QuerySnapshot,
  updateDoc,
  where,
} from "firebase/firestore";
import { DragDropContext, Draggable } from "react-beautiful-dnd";
import { useLocation, useNavigate } from "react-router-dom";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { useSubscription } from "../account/useSubscription";
import { Button } from "../components/Button";
import EmptyState from "../components/EmptyState";
import { Spinner } from "../components/Spinner";
import { converter } from "../firestore/converter";
import { useCreateScheme } from "../pages/ListsPage";
import { SchemeCard, StrictModeDroppable } from "./SchemeCard";

export interface SchemeData {
  id: string;
  owner: string;
  priority: number;
  goal?: string;
  steps?: Record<string, StepData> | "loading";
  archivedAt: FieldValue | null;
  members?: string[];
  memberPriority?: Record<string, number>;
}

export interface StepData {
  id: string;
  goal: string;
  completed: boolean;
  priority: number;
  assignee?: string;
  steps?: Record<string, StepData> | "loading";
}

export function getPriority(uid?: string, scheme?: SchemeData) {
  if (!uid) {
    return 0;
  }
  return (
    (scheme?.owner === uid ? scheme.priority : scheme?.memberPriority?.[uid]) ||
    0
  );
}

export function SchemesList() {
  const createScheme = useCreateScheme();
  const firestore = useFirestore();
  const user = useUser();

  const uid = user.data?.uid;

  const subscription = useSubscription();

  const schemesCollection = collection(firestore, "schemes");
  const schemesQuery = query(
    schemesCollection,
    where("owner", "==", uid),
    where("archivedAt", "==", null),
    orderBy("priority", "desc"),
    limit(subscription.subscribed ? 100 : 5)
  );
  const schemes = useFirestoreCollectionData<SchemeData>(schemesQuery as any, {
    idField: "id",
  });

  const sharedQuery = query(
    schemesCollection,
    where("members", "array-contains", uid),
    where("archivedAt", "==", null),
    orderBy("priority", "desc"),
    limit(subscription.subscribed ? 100 : 5)
  );
  const shared = useFirestoreCollectionData<SchemeData>(sharedQuery as any, {
    idField: "id",
  });

  const navigate = useNavigate();

  if (user.status === "loading") {
    return <Spinner />;
  }

  if (schemes.status === "loading") {
    return <Spinner />;
  }

  if (shared.status === "loading") {
    return <Spinner />;
  }

  const sortedList = [...(schemes.data || []), ...(shared.data || [])];
  sortedList.sort(
    (a, b) =>
      ((b.owner === uid || uid === undefined
        ? b.priority
        : b.memberPriority?.[uid]) || 0) -
      ((a.owner === uid || uid === undefined
        ? a.priority
        : a.memberPriority?.[uid]) || 0)
  );

  return (
    <>
      <div className="flex flex-row-reverse pb-4">
        <Button
          disabled={schemes.data?.length >= 100}
          onClick={() => {
            if (!subscription.subscribed && schemes.data?.length >= 5) {
              navigate("/app/subscription");
            } else {
              createScheme({ priority: (sortedList[0]?.priority || 0) + 1 });
            }
          }}
        >
          Create
        </Button>
      </div>
      <DragDropContext
        onDragEnd={(result) => {
          if (!result.destination) {
            return;
          }

          const ref = doc(
            firestore,
            "schemes",
            sortedList[result.source.index].id
          );

          const priority =
            result.destination.index < result.source.index
              ? result.destination.index === 0
                ? getPriority(uid, sortedList[0]) + 1
                : (getPriority(uid, sortedList[result.destination.index]) +
                    getPriority(
                      uid,
                      sortedList[result.destination.index - 1]
                    )) /
                  2
              : result.destination.index === sortedList.length - 1
              ? getPriority(uid, sortedList[sortedList.length - 1]) - 1
              : (getPriority(uid, sortedList[result.destination.index]) +
                  getPriority(uid, sortedList[result.destination.index + 1])) /
                2;

          if (sortedList[result.source.index].owner === uid) {
            updateDoc(ref, {
              priority,
            });
          } else {
            updateDoc(ref, {
              [`memberPriority.${uid}`]: priority,
            });
          }
        }}
      >
        <StrictModeDroppable droppableId="droppableList" type="schemeList">
          {(provided, snapshot) => (
            <ul
              role="list"
              className="space-y-3"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {sortedList.length > 0 ? (
                sortedList.map((scheme, index) => (
                  <Draggable
                    key={scheme.id}
                    draggableId={scheme.id}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <li
                        className="bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        // className={
                        //   (snapshot.isDragging ? "bg-white shadow" : "") +
                        //   " py-2 px-1"
                        // }
                      >
                        <SchemeCard
                          scheme={scheme}
                          dragHandleProps={provided.dragHandleProps}
                          subscribed={subscription.subscribed}
                        />
                      </li>
                    )}
                  </Draggable>
                ))
              ) : (
                <div className="text-center">
                  <svg
                    className="mx-auto h-12 w-12 text-gray-400"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      vectorEffect="non-scaling-stroke"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
                    />
                  </svg>
                  <h3 className="mt-2 text-sm font-medium text-gray-900">
                    No lists
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by creating a new list.
                  </p>
                  <div className="mt-6">
                    <Button
                      onClick={() => {
                        createScheme({ priority: 0 });
                      }}
                    >
                      <PlusIcon
                        className="-ml-1 mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      New List
                    </Button>
                  </div>
                </div>
              )}
              {provided.placeholder}
            </ul>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </>
  );
}
