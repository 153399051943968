import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  limit,
  query,
  where,
} from "firebase/firestore";
import {
  useFirestore,
  useFirestoreCollection,
  useFirestoreCollectionData,
} from "reactfire";

export interface Invite {
  schemeId: string;
}

export function useCreateListInvite() {
  const firestore = useFirestore();
  const invitesRef = collection(firestore, "invites");

  return (schemeId: string) => addDoc(invitesRef, { schemeId });
}

export function useDeleteInvite() {
  const firestore = useFirestore();

  return (id: string) => {
    return deleteDoc(doc(firestore, "invites", id));
  };
}

export function useListInvite(schemeId: string) {
  const firestore = useFirestore();
  const invitesCollection = collection(firestore, "invites");

  const inviteQuery = query(
    invitesCollection,
    where("schemeId", "==", schemeId),
    limit(1)
  );

  const invite = useFirestoreCollectionData(inviteQuery, {
    idField: "id",
  });
  if (invite.status === "loading" || !invite.data || invite.data.length === 0) {
    return undefined;
  } else {
    return {
      schemeId,
      inviteCode: invite.data[0].id,
    };
  }
}
