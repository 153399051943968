import { collection, doc, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";

export function useSubscription() {
  // const firestore = useFirestore();
  // const user = useUser();

  // const subscription = useFirestoreCollectionData(
  //   query(
  //     collection(firestore, "customers", user.data!.uid, "subscriptions"),
  //     where("status", "in", ["trialing", "active"])
  //   ),
  //   {
  //     initialData: undefined,
  //   }
  // );

  // const subscribed = subscription.data?.[0] !== undefined;
  // return {
  //   subscribed,
  //   type: subscribed
  //     ? subscription.data?.[0]?.price.id === "price_1MAR6nFZLEAMEf8Ghvp1eXJX"
  //       ? "annual"
  //       : "monthly"
  //     : undefined,
  // };
  return {
    subscribed: true,
    type: "annual",
  };
}
