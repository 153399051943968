import React from "react";
import { useAuth, SuspenseWithPerf, useSigninCheck } from "reactfire";
import firebase from "firebase/compat/app";
import { useLocation } from "react-router-dom";
import { GoogleAuthProvider, signInWithPopup } from "firebase/auth";
import { Spinner } from "../components/Spinner";
import { Button } from "../components/Button";

export const signOut = (auth: any) =>
  auth.signOut().then(() => console.log("signed out"));
const signIn = async (auth: any) => {
  const provider = new GoogleAuthProvider();

  await signInWithPopup(auth, provider);
};

export const AuthWrapper = ({
  children,
  fallback,
}: React.PropsWithChildren<{ fallback: JSX.Element }>): JSX.Element => {
  const { status, data: signInCheckResult } = useSigninCheck();

  if (!children) {
    throw new Error("Children must be provided");
  }
  if (status === "loading") {
    return <Spinner />;
  } else if (signInCheckResult.signedIn === true) {
    return children as JSX.Element;
  }

  return fallback;
};

export const SignInForm = () => {
  const auth = useAuth();

  return (
    <div className="flex min-h-full text-center align-middle items-center justify-center py-12 px-4 sm:px-6 lg:px-8 flex-col space-y-6">
      <div className="w-full max-w-md space-y-8">
        <h2 className="mt-6 text-center text-2xl font-bold tracking-tight text-gray-900">
          Sign in
        </h2>
      </div>
      <div className="w-full max-w-md space-y-8">
        Sign in with one of the providers below
      </div>
      <button
        onClick={() => signIn(auth)}
        type="button"
        className="inline-flex items-center px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-md shadow-md text-black bg-white hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-rose-500"
      >
        <img
          className="h-5 w-5 mr-2"
          alt="Sign in with Google"
          src="https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg"
        />
        Sign in with Google
      </button>
      <div className="w-full max-w-md space-y-8">
        By signing in and using this service you are agreeing to the{" "}
        <a className="text-orange-400" href="/tos">
          Terms of Service
        </a>{" "}
        and{" "}
        <a className="text-orange-400" href="/privacy">
          Privacy Policy
        </a>
        .
      </div>
    </div>
  );
};

export const AuthCheck = ({ children }: React.PropsWithChildren) => {
  const { status, data: signinResult } = useSigninCheck();

  if (status === "loading") {
    return <Spinner />;
  }

  const { signedIn, user } = signinResult;

  if (signedIn === true) {
    return children as JSX.Element;
  } else {
    return <SignInForm />;
  }
};
