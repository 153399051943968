import { Fragment, useEffect, useId, useRef, useState } from "react";
import { Tab } from "@headlessui/react";
import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";
import { useDebouncedCallback } from "use-debounce";

import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxXMarkIcon,
  BoltIcon,
  PencilSquareIcon,
  CommandLineIcon,
} from "@heroicons/react/24/outline";

import { AppScreen } from "./AppScreen";
import { CircleBackground } from "./CircleBackground";
import { Container } from "./Container";
import { PhoneFrame } from "./PhoneFrame";
import { SchemeCard } from "../../scheme/SchemeCard";

const MotionAppScreenHeader = motion(AppScreen.Header);
const MotionAppScreenBody = motion(AppScreen.Body);

const features = [
  {
    name: "Set goals you want to achieve",
    description:
      '"Clean my house", "Cook a roast chicken", "Become a pirate" or whatever you want.',
    icon: PencilSquareIcon,
  },
  {
    name: "Break those goals down into individual tasks",
    description:
      "Autodo's AI will intelligently break the goal down into smaller tasks.",
    icon: BoltIcon,
  },
  {
    name: "Generate sub-tasks to get even more detail",
    description:
      "Every task is a goal in itself, so you can have Autodo drill in and break down tasks into smaller sub-steps if you want more detail.",
    icon: CommandLineIcon,
  },
];

function DeviceUserIcon(props) {
  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
      <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 23a3 3 0 100-6 3 3 0 000 6zm-1 2a4 4 0 00-4 4v1a2 2 0 002 2h6a2 2 0 002-2v-1a4 4 0 00-4-4h-2z"
        fill="#737373"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a4 4 0 014-4h14a4 4 0 014 4v24a4.002 4.002 0 01-3.01 3.877c-.535.136-.99-.325-.99-.877s.474-.98.959-1.244A2 2 0 0025 28V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 001.041 1.756C8.525 30.02 9 30.448 9 31s-.455 1.013-.99.877A4.002 4.002 0 015 28V4z"
        fill="#A3A3A3"
      />
    </svg>
  );
}

function DeviceNotificationIcon(props) {
  return (
    <svg viewBox="0 0 32 32" aria-hidden="true" {...props}>
      <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0a4 4 0 00-4 4v24a4 4 0 004 4h14a4 4 0 004-4V4a4 4 0 00-4-4H9zm0 2a2 2 0 00-2 2v24a2 2 0 002 2h14a2 2 0 002-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9z"
        fill="#A3A3A3"
      />
      <path
        d="M9 8a2 2 0 012-2h10a2 2 0 012 2v2a2 2 0 01-2 2H11a2 2 0 01-2-2V8z"
        fill="#737373"
      />
    </svg>
  );
}

function DeviceTouchIcon(props) {
  let id = useId();

  return (
    <svg viewBox="0 0 32 32" fill="none" aria-hidden="true" {...props}>
      <defs>
        <linearGradient
          id={`${id}-gradient`}
          x1={14}
          y1={14.5}
          x2={7}
          y2={17}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#737373" />
          <stop offset={1} stopColor="#D4D4D4" stopOpacity={0} />
        </linearGradient>
      </defs>
      <circle cx={16} cy={16} r={16} fill="#A3A3A3" fillOpacity={0.2} />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5 4a4 4 0 014-4h14a4 4 0 014 4v13h-2V4a2 2 0 00-2-2h-1.382a1 1 0 00-.894.553l-.448.894a1 1 0 01-.894.553h-6.764a1 1 0 01-.894-.553l-.448-.894A1 1 0 0010.382 2H9a2 2 0 00-2 2v24a2 2 0 002 2h4v2H9a4 4 0 01-4-4V4z"
        fill="#A3A3A3"
      />
      <path
        d="M7 22c0-4.694 3.5-8 8-8"
        stroke={`url(#${id}-gradient)`}
        strokeWidth={2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21 20l.217-5.513a1.431 1.431 0 00-2.85-.226L17.5 21.5l-1.51-1.51a2.107 2.107 0 00-2.98 0 .024.024 0 00-.005.024l3.083 9.25A4 4 0 0019.883 32H25a4 4 0 004-4v-5a3 3 0 00-3-3h-5z"
        fill="#A3A3A3"
      />
    </svg>
  );
}

const headerAnimation = {
  initial: { opacity: 0, transition: { duration: 0.3 } },
  animate: { opacity: 1, transition: { duration: 0.3, delay: 0.3 } },
  exit: { opacity: 0, transition: { duration: 0.3 } },
};

const maxZIndex = 2147483647;

const bodyVariantBackwards = {
  opacity: 0.4,
  scale: 0.8,
  zIndex: 0,
  filter: "blur(4px)",
  zIndex: 0,
  transition: { duration: 0.4 },
};

const bodyVariantForwards = (custom) => ({
  y: "100%",
  zIndex: maxZIndex - custom.changeCount,
  transition: { duration: 0.4 },
});

const bodyAnimation = {
  initial: "initial",
  animate: "animate",
  exit: "exit",
  variants: {
    initial: (custom) =>
      custom.isForwards ? bodyVariantForwards(custom) : bodyVariantBackwards,
    animate: (custom) => ({
      y: "0%",
      opacity: 1,
      scale: 1,
      zIndex: maxZIndex / 2 - custom.changeCount,
      filter: "blur(0px)",
      transition: { duration: 0.4 },
    }),
    exit: (custom) =>
      custom.isForwards ? bodyVariantBackwards : bodyVariantForwards(custom),
  },
};

function SchemeScreen({ stage }) {
  const [scheme, setScheme] = useState({
    id: "example",
    owner: "example",
    goal: "Become a pirate",
    steps: [],
  });

  useEffect(() => {
    if (stage === 0) {
      setScheme({
        id: "example",
        owner: "example",
        goal: "Become a pirate",
        steps: [],
      });
    }
    if (stage === 1) {
      setScheme({
        id: "example",
        owner: "example",
        goal: "Become a pirate",
        steps: "loading",
      });
      setTimeout(() => {
        setScheme({
          id: "example",
          owner: "example",
          goal: "Become a pirate",
          steps: [
            {
              id: "cd625a63-222e-4bbd-9aae-62c10c2718a1",
              goal: "Learn the basics of sailing and navigation.",
              completed: false,
            },
            {
              id: "caef62dd-4832-44bd-8769-a400aa008395",
              goal: "Acquire a boat and other necessary equipment.",
              completed: false,
            },
            {
              completed: false,
              steps: [],
              id: "a0af6bd1-6a2d-4e8a-938d-fffd3d1690a1",
              goal: "Learn the pirate code.",
            },
            {
              goal: "Gather a crew.",
              id: "75a5875e-e494-42c3-8278-e5710469943e",
              completed: false,
            },
            {
              goal: "Find a suitable base of operations.",
              id: "cbdd5864-92f5-4452-87cf-047902b23d36",
              completed: false,
            },
            {
              goal: "Set sail and search for treasure.",
              id: "0309e70c-e27a-4310-a998-8ae22c1eaf85",
              completed: false,
            },
          ],
        });
      }, 1000);
    }
    if (stage === 2) {
      setScheme({
        id: "example",
        owner: "example",
        goal: "Become a pirate",
        steps: [
          {
            id: "cd625a63-222e-4bbd-9aae-62c10c2718a1",
            goal: "Learn the basics of sailing and navigation.",
            completed: false,
          },
          {
            id: "caef62dd-4832-44bd-8769-a400aa008395",
            goal: "Acquire a boat and other necessary equipment.",
            completed: false,
            steps: "loading",
          },
          {
            completed: false,
            id: "a0af6bd1-6a2d-4e8a-938d-fffd3d1690a1",
            goal: "Learn the pirate code.",
          },
          {
            goal: "Gather a crew.",
            id: "75a5875e-e494-42c3-8278-e5710469943e",
            completed: false,
          },
          {
            goal: "Find a suitable base of operations.",
            id: "cbdd5864-92f5-4452-87cf-047902b23d36",
            completed: false,
          },
          {
            goal: "Set sail and search for treasure.",
            id: "0309e70c-e27a-4310-a998-8ae22c1eaf85",
            completed: false,
          },
        ],
      });
      setTimeout(() => {
        setScheme({
          id: "example",
          owner: "example",
          goal: "Become a pirate",
          steps: [
            {
              id: "cd625a63-222e-4bbd-9aae-62c10c2718a1",
              goal: "Learn the basics of sailing and navigation.",
              completed: false,
            },
            {
              id: "caef62dd-4832-44bd-8769-a400aa008395",
              goal: "Acquire a boat and other necessary equipment.",
              completed: false,
              steps: [
                {
                  completed: false,
                  goal: "Pirate hat",
                  id: "11ef7d95-5551-4350-9512-1a45c89b1819",
                },
                {
                  goal: "Eye patch",
                  completed: false,
                  id: "47c58cf1-4c13-49ef-b71e-eb9ce98abae1",
                },
                {
                  id: "55b1c582-ebc5-41e8-868e-8a05d1228ac1",
                  goal: "Bandana",
                  completed: false,
                },
                {
                  goal: "Sword",
                  id: "a5047948-9051-4cb3-833e-5956936a8536",
                  completed: false,
                },
                {
                  completed: false,
                  goal: "Parrot",
                  id: "dba44532-fab2-407b-9b75-b7101f3cc839",
                },
                {
                  id: "16098b25-0882-461c-8469-32f0cdb84459",
                  completed: false,
                  goal: "Compass",
                },
                {
                  id: "31f0a627-d5e9-4700-964f-002f5c1bb31e",
                  goal: "Ship",
                  completed: false,
                },
                {
                  goal: "Map",
                  id: "d2399b04-8618-4b81-bcba-88ca4b6156ff",
                  completed: false,
                },
              ],
            },
            {
              completed: false,
              id: "a0af6bd1-6a2d-4e8a-938d-fffd3d1690a1",
              goal: "Learn the pirate code.",
            },
            {
              goal: "Gather a crew.",
              id: "75a5875e-e494-42c3-8278-e5710469943e",
              completed: false,
            },
            {
              goal: "Find a suitable base of operations.",
              id: "cbdd5864-92f5-4452-87cf-047902b23d36",
              completed: false,
            },
            {
              goal: "Set sail and search for treasure.",
              id: "0309e70c-e27a-4310-a998-8ae22c1eaf85",
              completed: false,
            },
          ],
        });
      }, 1000);
    }
  }, [stage]);

  return (
    <div
      className={`w-full ${
        stage === 0 ? "h-50" : stage === 1 ? "h-96" : "h-128"
      } overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6 transition-all`}
    >
      <SchemeCard disabled scheme={scheme} />
    </div>
  );
}

function StepsScreen({ custom, animated = false }) {
  const [scheme, setScheme] = useState({
    id: "example",
    owner: "example",
    goal: "Become a pirate",
    steps: [],
  });
  useEffect(() => {
    // setTimeout(() => {
    //   setScheme(
    //     {
    //       id: "example",
    //       owner: "example",
    //       goal: "Become a pirate",
    //       steps: "loading",
    //     },
    //     10000
    //   );
    // });
    // setTimeout(() => {
    //   setScheme(
    //     {
    //       id: "example",
    //       owner: "example",
    //       goal: "Become a pirate",
    //       steps: [
    //         {
    //           id: "cd625a63-222e-4bbd-9aae-62c10c2718a1",
    //           goal: "Learn the basics of sailing and navigation.",
    //           completed: false,
    //         },
    //         {
    //           id: "caef62dd-4832-44bd-8769-a400aa008395",
    //           goal: "Acquire a boat and other necessary equipment.",
    //           completed: false,
    //         },
    //         {
    //           completed: false,
    //           steps: [],
    //           id: "a0af6bd1-6a2d-4e8a-938d-fffd3d1690a1",
    //           goal: "Learn the pirate code.",
    //         },
    //         {
    //           goal: "Gather a crew.",
    //           id: "75a5875e-e494-42c3-8278-e5710469943e",
    //           completed: false,
    //         },
    //         {
    //           goal: "Find a suitable base of operations.",
    //           id: "cbdd5864-92f5-4452-87cf-047902b23d36",
    //           completed: false,
    //         },
    //         {
    //           goal: "Set sail and search for treasure.",
    //           id: "0309e70c-e27a-4310-a998-8ae22c1eaf85",
    //           completed: false,
    //         },
    //       ],
    //     },
    //     3000
    //   );
    // });
  }, []);
  return (
    <div className="w-full">
      <MotionAppScreenHeader {...(animated ? headerAnimation : {})}>
        <SchemeCard disabled scheme={scheme} />
      </MotionAppScreenHeader>
    </div>
  );
}

function usePrevious(value) {
  let ref = useRef();

  useEffect(() => {
    ref.current = value;
  }, [value]);

  return ref.current;
}

function FeaturesDesktop() {
  let [changeCount, setChangeCount] = useState(0);
  let [selectedIndex, setSelectedIndex] = useState(0);
  const [currentTimeout, setCurrentTimeout] = useState(undefined);
  let prevIndex = usePrevious(selectedIndex);
  let isForwards = prevIndex === undefined ? true : selectedIndex > prevIndex;

  useEffect(() => {
    if (currentTimeout) {
      clearTimeout(currentTimeout);
    }
    setCurrentTimeout(
      setTimeout(() => {
        setSelectedIndex((selectedIndex + 1) % 3);
      }, 5000)
    );
  }, [selectedIndex]);

  let onChange = useDebouncedCallback(
    (selectedIndex) => {
      setSelectedIndex(selectedIndex);
      setChangeCount((changeCount) => changeCount + 1);
    },
    100,
    { leading: true }
  );

  return (
    <Tab.Group
      as="div"
      className="grid grid-cols-12 items-center gap-8 lg:gap-16 xl:gap-24"
      selectedIndex={selectedIndex}
      onChange={onChange}
      vertical
    >
      <Tab.List className="relative z-10 order-last col-span-6 space-y-6">
        {features.map((feature, featureIndex) => (
          <div
            key={feature.name}
            className="relative rounded-2xl transition-colors hover:bg-rose-800/30"
          >
            {featureIndex === selectedIndex && (
              <motion.div
                layoutId="activeBackground"
                className="absolute inset-0 bg-rose-400"
                initial={{ borderRadius: 16 }}
              />
            )}
            <div className="relative z-10 p-8">
              <feature.icon className="h-8 w-8" />
              <h3 className="mt-6 text-lg font-semibold text-white">
                <Tab className="text-left [&:not(:focus-visible)]:focus:outline-none">
                  <span className="absolute inset-0 rounded-2xl" />
                  {feature.name}
                </Tab>
              </h3>
              <p className="mt-2 text-sm text-black">{feature.description}</p>
            </div>
          </div>
        ))}
      </Tab.List>
      <div className="relative col-span-6">
        <SchemeScreen stage={selectedIndex} />

        {/* <Tab.Panels as={Fragment}>
          <AnimatePresence initial={false} custom={{ isForwards, changeCount }}>
            {features.map((feature, featureIndex) =>
              selectedIndex === featureIndex ? (
                <Tab.Panel
                  static
                  key={feature.name + changeCount}
                  className="col-start-1 row-start-1 flex focus:outline-offset-[32px] [&:not(:focus-visible)]:focus:outline-none"
                >
                  <feature.screen
                    animated
                    custom={{ isForwards, changeCount }}
                  />
                </Tab.Panel>
              ) : null
            )}
          </AnimatePresence>
        </Tab.Panels> */}
      </div>
    </Tab.Group>
  );
}

function FeaturesMobile() {
  let [activeIndex, setActiveIndex] = useState(0);
  let slideContainerRef = useRef();
  let slideRefs = useRef([]);

  useEffect(() => {
    let observer = new window.IntersectionObserver(
      (entries) => {
        for (let entry of entries) {
          if (entry.isIntersecting) {
            setActiveIndex(slideRefs.current.indexOf(entry.target));
            break;
          }
        }
      },
      {
        root: slideContainerRef.current,
        threshold: 0.6,
      }
    );

    for (let slide of slideRefs.current) {
      if (slide) {
        observer.observe(slide);
      }
    }

    return () => {
      observer.disconnect();
    };
  }, [slideContainerRef, slideRefs]);

  return (
    <>
      <div
        ref={slideContainerRef}
        className="-mb-4 flex snap-x snap-mandatory -space-x-4 overflow-x-auto overscroll-x-contain scroll-smooth pb-4 [scrollbar-width:none] sm:-space-x-6 [&::-webkit-scrollbar]:hidden"
      >
        {features.map((feature, featureIndex) => (
          <div
            key={featureIndex}
            ref={(ref) => (slideRefs.current[featureIndex] = ref)}
            className="w-full flex-none snap-center px-4 sm:px-6"
          >
            <div className="relative transform overflow-hidden rounded-2xl bg-rose-400 px-5 py-6">
              <div className=" inset-x-0 bottom-0 bg-rose-400/95 p-6 backdrop-blur sm:p-10">
                <feature.icon className="h-8 w-8" />
                <h3 className="mt-6 text-sm font-semibold text-white sm:text-lg">
                  {feature.name}
                </h3>
                <p className="mt-2 text-sm text-black">{feature.description}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="mt-6 flex justify-center gap-3">
        {features.map((_, featureIndex) => (
          <button
            type="button"
            key={featureIndex}
            className={clsx(
              "relative h-0.5 w-4 rounded-full",
              featureIndex === activeIndex ? "bg-gray-300" : "bg-gray-500"
            )}
            aria-label={`Go to slide ${featureIndex + 1}`}
            onClick={() => {
              slideRefs.current[featureIndex].scrollIntoView({
                block: "nearest",
                inline: "nearest",
              });
            }}
          >
            <span className="absolute -inset-x-1.5 -inset-y-3" />
          </button>
        ))}
      </div>
    </>
  );
}

export function PrimaryFeatures() {
  return (
    <section
      id="features"
      aria-label="Autodo features"
      className="bg-gradient-to-r from-orange-400 to-pink-500 py-20 sm:py-32"
    >
      <Container>
        <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-3xl">
          <h2 className="text-3xl font-medium tracking-tight text-white">
            Introducing the AI-powered to-do app that will help you reach new
            heights!
          </h2>
          <p className="mt-2 text-lg text-black">
            Autodo was built to help you achieve your goals. Sometimes the
            hardest part of getting things done is knowing where to start.
            Autodo not only tracks your tasks, but also automatically helps you
            figure out what they should be.
          </p>
        </div>
      </Container>
      <div className="mt-16 md:hidden">
        <FeaturesMobile />
      </div>
      <Container className="hidden md:mt-20 md:block">
        <FeaturesDesktop />
      </Container>
    </section>
  );
}
