import {
  addDoc,
  collection,
  deleteDoc,
  deleteField,
  doc,
  FieldValue,
  runTransaction,
  serverTimestamp,
  setDoc,
  updateDoc,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { Fragment, useEffect, useState } from "react";
import {
  useFirestore,
  useFirestoreDocData,
  useFunctions,
  useUser,
} from "reactfire";
import { SchemeData, StepData } from "./SchemesList";
import {
  ArchiveBoxArrowDownIcon,
  ArchiveBoxXMarkIcon,
  BoltIcon,
  CheckIcon,
  ChevronUpDownIcon,
  LinkIcon,
  PencilSquareIcon,
  PlusCircleIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  ShareIcon,
  SparklesIcon,
  TrashIcon,
  UserPlusIcon,
  XMarkIcon,
} from "@heroicons/react/20/solid";
import { Spinner } from "../components/Spinner";
import { v4 } from "uuid";
import { Tooltip } from "flowbite-react";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DroppableProps,
} from "react-beautiful-dnd";
import { Dialog, Listbox, Transition } from "@headlessui/react";
import { useListInvite } from "./invites";
import { UserIcon } from "@heroicons/react/24/outline";

export const StrictModeDroppable = ({ children, ...props }: DroppableProps) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

function useDeleteScheme() {
  const firestore = useFirestore();

  return async (id: string) => {
    return await deleteDoc(doc(firestore, "schemes", id));
  };
}

export interface SchemeCardProps {
  scheme: SchemeData;
  disabled?: boolean;
  dragHandleProps?: any;
  subscribed?: boolean;
}

export function SchemeCard({
  scheme,
  disabled,
  dragHandleProps,
  subscribed,
}: SchemeCardProps) {
  const firestore = useFirestore();
  const ref = doc(firestore, "schemes", scheme.id);

  const user = useUser();
  const deleteScheme = useDeleteScheme();

  const [editGoal, setEditGoal] = useState(false);
  const [goalText, setGoalText] = useState(scheme.goal);
  const functions = useFunctions();
  const convertGoalToSteps = httpsCallable(functions, "convertGoalToSteps");

  const [open, setOpen] = useState(false);

  const loading = scheme.steps === "loading";
  const schemeSteps =
    scheme.steps && scheme.steps !== "loading"
      ? Object.values(scheme.steps)
      : undefined;

  schemeSteps?.sort((a, b) => a.priority - b.priority);

  return (
    <div>
      {editGoal ? (
        <>
          <label htmlFor="goal" className="sr-only">
            Goal
          </label>
          <div>
            <textarea
              rows={2}
              name="goal"
              id="goal"
              className="block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring-rose-500 sm:text-sm p-2"
              placeholder="Add a goal..."
              defaultValue={goalText}
              onChange={(event) => setGoalText(event.target.value)}
              onBlur={async () => {
                await updateDoc(ref, {
                  goal: goalText,
                });
                setEditGoal(false);
              }}
              onKeyDown={async (event) => {
                if (event.key === "Enter") {
                  event.preventDefault();
                  await updateDoc(ref, {
                    goal: goalText,
                  });
                  setEditGoal(false);
                }
              }}
              autoFocus
            />
          </div>
        </>
      ) : (
        <div className="flex justify-between" {...dragHandleProps}>
          <h3
            onClick={() => {
              if (!scheme.archivedAt && !disabled) {
                setEditGoal(true);
              }
            }}
            className="text-lg font-medium leading-6 text-gray-900"
          >
            {scheme.goal || (
              <div className="flex row-span-1 text-gray-500">
                Enter a goal... <PencilSquareIcon className="h-5 w-5 ml-2" />
              </div>
            )}
          </h3>
        </div>
      )}
      <div className="relative py-4">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-gray-300" />
        </div>
        <div className="relative flex justify-center">
          <span className="isolate inline-flex -space-x-px rounded-md shadow-sm">
            {!scheme.archivedAt && (
              <>
                <Tooltip content="Add task">
                  <button
                    type="button"
                    disabled={scheme.steps === "loading" || disabled}
                    onClick={async () => {
                      const id = v4();
                      updateDoc(ref, {
                        [`steps.${id}`]: {
                          id,
                          goal: "",
                          completed: false,
                          priority: schemeSteps
                            ? schemeSteps[schemeSteps.length - 1].priority + 1
                            : 0,
                        },
                      });
                    }}
                    className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                  >
                    <span className="sr-only">Add</span>
                    <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </Tooltip>
                <Tooltip content="AI generate">
                  <button
                    type="button"
                    disabled={scheme.steps === "loading" || disabled}
                    onClick={async () => {
                      if (goalText === "") {
                        return;
                      }
                      await updateDoc(ref, {
                        steps: "loading",
                      });
                      const steps = (await convertGoalToSteps({
                        goal: goalText,
                      })) as { data: string[] };
                      await updateDoc(ref, {
                        steps: steps.data
                          .map((stepText, index) => {
                            const newId = v4();
                            return {
                              [newId]: {
                                id: newId,
                                goal: stepText,
                                completed: false,
                                priority: index,
                              },
                            };
                          })
                          .reduce((a, b) => ({ ...a, ...b }), {}),
                      });
                    }}
                    className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                  >
                    <span className="sr-only">Generate</span>
                    <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </Tooltip>
                {scheme.owner === user.data?.uid && subscribed && (
                  <Tooltip content="Share">
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={() => setOpen(true)}
                      className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                    >
                      <span className="sr-only">Share</span>
                      <ShareIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </Tooltip>
                )}
              </>
            )}
            {scheme.owner === user.data?.uid ? (
              <>
                <Tooltip content={scheme.archivedAt ? "Unarchive" : "Archive"}>
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={() =>
                      updateDoc(ref, {
                        archivedAt: scheme.archivedAt
                          ? null
                          : serverTimestamp(),
                      })
                    }
                    className={`relative inline-flex items-center ${
                      scheme.archivedAt ? "rounded-l-md" : ""
                    } border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500`}
                  >
                    <span className="sr-only">
                      {scheme.archivedAt ? "Unarchive" : "Archive"}
                    </span>
                    {scheme.archivedAt ? (
                      <ArchiveBoxArrowDownIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArchiveBoxXMarkIcon
                        className="h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                  </button>
                </Tooltip>
                <Tooltip content="Delete">
                  <button
                    type="button"
                    disabled={disabled}
                    onClick={() => deleteScheme(scheme.id)}
                    className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                  >
                    <span className="sr-only">Delete</span>
                    <TrashIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </Tooltip>
              </>
            ) : (
              <Tooltip content="Leave">
                <button
                  type="button"
                  disabled={disabled}
                  onClick={() =>
                    updateDoc(doc(firestore, "schemes", scheme.id), {
                      members: scheme.members?.filter(
                        (member) => member !== user.data?.uid
                      ),
                    })
                  }
                  className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                >
                  <span className="sr-only">Leave</span>
                  <XMarkIcon className="h-5 w-5" aria-hidden="true" />
                </button>
              </Tooltip>
            )}
          </span>
        </div>
      </div>
      <div className="space-y-3">
        {scheme.steps === "loading" ? (
          <Spinner />
        ) : !schemeSteps || schemeSteps.length === 0 ? (
          <div className="text-center mb-6">
            <svg
              className="mx-auto h-12 w-12 text-gray-400"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
              aria-hidden="true"
            >
              <path
                vectorEffect="non-scaling-stroke"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
              />
            </svg>
            <h3 className="mt-2 text-sm font-medium text-gray-900">No tasks</h3>
            <p className="mt-1 text-sm text-gray-500">
              Use the buttons above to add tasks or let Autodo generate some for
              you.
            </p>
          </div>
        ) : loading ? (
          <Spinner />
        ) : (
          <Steps
            parentId={scheme.id}
            scheme={scheme}
            updateStep={(id, value) => {
              return updateDoc(ref, {
                [id]: value,
              });
            }}
            steps={schemeSteps}
          />
        )}
      </div>
      {scheme.owner === user.data?.uid && subscribed && (
        <SchemeSharing open={open} setOpen={setOpen} scheme={scheme} />
      )}
    </div>
  );
}

interface StepsProps {
  parentId: string;
  steps: StepData[] | "loading";
  scheme: SchemeData;
  updateStep(id: string, value: any): Promise<void>;
}

function Steps({ parentId, steps, updateStep, scheme }: StepsProps) {
  const onDragEnd = (result: any) => {
    // // dropped outside the list
    if (!result.destination || steps === "loading") {
      return;
    }

    const priority =
      result.destination.index < result.source.index
        ? result.destination.index === 0
          ? steps[0].priority - 1
          : (steps[result.destination.index].priority +
              steps[result.destination.index - 1].priority) /
            2
        : result.destination.index === steps.length - 1
        ? steps[steps.length - 1].priority + 1
        : (steps[result.destination.index].priority +
            steps[result.destination.index + 1].priority) /
          2;

    updateStep(`steps.${steps[result.source.index].id}.priority`, priority);
  };

  return (
    <div className="space-y-3 ml-5">
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable
          droppableId={`droppable-${parentId}`}
          type={`${parentId}`}
        >
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className="flex flex-col"
            >
              {steps &&
                (steps as StepData[]).map((innerStep, index) => (
                  <div
                    style={{
                      zIndex: steps.length - index,
                    }}
                  >
                    <SchemeStep
                      key={innerStep.id}
                      scheme={scheme}
                      step={innerStep}
                      index={index}
                      updateStep={(id: string, newStep: StepData) => {
                        return updateStep(
                          `steps.${innerStep.id}.${id}`,
                          newStep
                        );
                      }}
                      deleteStep={() => {
                        return updateStep(
                          `steps.${innerStep.id}`,
                          deleteField()
                        );
                      }}
                    />
                  </div>
                ))}
              {provided.placeholder}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>
    </div>
  );
}

interface SchemeSharingProps {
  open: boolean;
  setOpen: any;
  scheme: SchemeData;
}

function SchemeSharing({ open, setOpen, scheme }: SchemeSharingProps) {
  const invite = useListInvite(scheme.id);
  const firestore = useFirestore();

  const user = useUser();

  if (user.status === "loading") {
    return null;
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={setOpen}>
        <div className="fixed inset-0" />

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom="translate-x-full"
                enterTo="translate-x-0"
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom="translate-x-0"
                leaveTo="translate-x-full"
              >
                <Dialog.Panel className="pointer-events-auto w-screen max-w-md">
                  <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                    <div className="h-0 flex-1 overflow-y-auto">
                      <div className="bg-orange-400 py-6 px-4 sm:px-6">
                        <div className="flex items-center justify-between">
                          <Dialog.Title className="text-lg font-medium text-white">
                            {scheme.goal}
                          </Dialog.Title>
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              type="button"
                              className="rounded-md bg-orange-500 text-rose-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                              onClick={() => setOpen(false)}
                            >
                              <span className="sr-only">Close panel</span>
                              <XMarkIcon
                                className="h-6 w-6"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </div>
                        <div className="mt-1">
                          <p className="text-sm text-rose-100">
                            Control how this list is shared below.
                          </p>
                        </div>
                      </div>
                      <div className="flex flex-1 flex-col justify-between">
                        <div className="divide-y divide-gray-200 px-4 sm:px-6">
                          <div className="space-y-6 pt-6 pb-5">
                            <div>
                              <h3 className="text-sm font-medium text-gray-900">
                                Team Members
                              </h3>
                              <div className="mt-2">
                                <div className="flex flex-col space-y-1 items-start">
                                  {scheme.members &&
                                  scheme.members.length > 0 ? (
                                    scheme.members.map((uid) => (
                                      <span className="inline-flex justify-center space-x-1 items-center">
                                        <User uid={uid} showName />
                                        <button
                                          onClick={() => {
                                            updateDoc(
                                              doc(
                                                firestore,
                                                "schemes",
                                                scheme.id
                                              ),
                                              {
                                                members: scheme.members?.filter(
                                                  (member) => member !== uid
                                                ),
                                              }
                                            );
                                          }}
                                          type="button"
                                          className="inline-flex h-6 w-6 flex-shrink-0 items-center justify-center rounded-full border-2 border-dashed border-gray-200 bg-white text-gray-400 hover:border-gray-300 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                                        >
                                          <span className="sr-only">
                                            Remove member
                                          </span>
                                          <XMarkIcon
                                            className="h-6 w-6"
                                            aria-hidden="true"
                                          />
                                        </button>
                                      </span>
                                    ))
                                  ) : (
                                    <span className="text-gray-500">
                                      No one yet!
                                    </span>
                                  )}
                                </div>
                              </div>
                            </div>
                            <fieldset>
                              <legend className="text-sm font-medium text-gray-900">
                                Privacy
                              </legend>
                              <div className="mt-2 space-y-5">
                                <div>
                                  <div className="relative flex items-start">
                                    <div className="absolute flex h-5 items-center">
                                      <input
                                        id="privacy-private"
                                        name="privacy"
                                        aria-describedby="privacy-private-to-project-description"
                                        type="radio"
                                        className="h-4 w-4 border-gray-300 text-rose-600 focus:ring-rose-500"
                                        checked={!invite}
                                        onChange={(event) => {
                                          if (event.target.checked && invite) {
                                            deleteDoc(
                                              doc(
                                                firestore,
                                                "invites",
                                                invite?.inviteCode
                                              )
                                            );
                                          }
                                        }}
                                      />
                                    </div>
                                    <div className="pl-7 text-sm">
                                      <label
                                        htmlFor="privacy-private"
                                        className="font-medium text-gray-900"
                                      >
                                        Private
                                      </label>
                                      <p
                                        id="privacy-private-description"
                                        className="text-gray-500"
                                      >
                                        You are the only one able to access this
                                        list.
                                      </p>
                                    </div>
                                  </div>
                                </div>
                                <div className="relative flex items-start">
                                  <div className="absolute flex h-5 items-center">
                                    <input
                                      id="privacy-public"
                                      name="privacy"
                                      aria-describedby="privacy-public-description"
                                      type="radio"
                                      className="h-4 w-4 border-gray-300 text-rose-600 focus:ring-rose-500"
                                      checked={!!invite}
                                      onChange={(event) => {
                                        if (event.target.checked) {
                                          setDoc(
                                            doc(
                                              firestore,
                                              "profiles",
                                              user.data!.uid
                                            ),
                                            {
                                              photoURL: user.data!.photoURL,
                                              displayName:
                                                user.data!.displayName,
                                            }
                                          );
                                          addDoc(
                                            collection(firestore, "invites"),
                                            {
                                              schemeId: scheme.id,
                                            }
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                  <div className="pl-7 text-sm">
                                    <label
                                      htmlFor="privacy-public"
                                      className="font-medium text-gray-900"
                                    >
                                      Shared
                                    </label>
                                    <p
                                      id="privacy-public-description"
                                      className="text-gray-500"
                                    >
                                      Everyone with the link will be able to see
                                      and edit this list.
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </fieldset>
                          </div>
                          <div className="pt-4 pb-6">
                            {invite && (
                              <div className="flex text-sm">
                                <a
                                  onClick={() => {
                                    navigator.clipboard.writeText(
                                      `${window.location.origin}/app/join/${invite?.inviteCode}`
                                    );
                                  }}
                                  href="#"
                                  className="group inline-flex items-center font-medium text-orange-400 hover:text-orange-900"
                                >
                                  <LinkIcon
                                    className="h-5 w-5 text-orange-400 group-hover:text-orange-900"
                                    aria-hidden="true"
                                  />
                                  <span className="ml-2">Copy link</span>
                                </a>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* <div className="flex flex-shrink-0 justify-end px-4 py-4">
                      <button
                        type="button"
                        className="rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                        onClick={() => setOpen(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="ml-4 inline-flex justify-center rounded-md border border-transparent bg-rose-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-rose-700 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2"
                      >
                        Save
                      </button>
                    </div> */}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

interface UserProps {
  uid: string;
  showName?: boolean;
  onClick?: any;
}
function User({ uid, showName, onClick }: UserProps) {
  const firestore = useFirestore();
  const user = useFirestoreDocData(doc(firestore, "profiles", uid));

  if (user.status === "loading") {
    return null;
  }
  return (
    <span className="rounded-full">
      <img
        className="inline-block min-h-max h-7 w-7 min-w-max rounded-full"
        src={user.data.photoURL}
        // alt={user.data.displayName}
      />
      {showName && <span className="ml-2">{user.data.displayName}</span>}
    </span>
  );
}

interface SchemeStepProps {
  disabled?: boolean;
  scheme: SchemeData;
  step: StepData;
  updateStep(id: string, value: any): Promise<void>;
  deleteStep(): Promise<void>;
  index: number;
}

function SchemeStep({
  scheme,
  step,
  updateStep,
  deleteStep,
  disabled,
  index,
}: SchemeStepProps) {
  const functions = useFunctions();
  const convertGoalToSteps = httpsCallable(functions, "convertGoalToSteps");

  const [editGoal, setEditGoal] = useState(false);
  const [showControls, setShowControls] = useState(false);
  const [mouseOverControls, setMouseOverControls] = useState(false);
  const [goalText, setGoalText] = useState(step.goal);

  const loading = step.steps === "loading";
  const steps =
    step.steps && step.steps !== "loading"
      ? Object.values(step.steps)
      : undefined;

  steps?.sort((a, b) => a.priority - b.priority);

  return (
    <>
      <Draggable
        key={step.id}
        draggableId={step.id}
        index={index}
        isDragDisabled={disabled || !!scheme.archivedAt}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            className={
              (snapshot.isDragging ? "bg-white shadow" : "") + " py-2 px-1"
            }
          >
            <div className="relative flex" {...provided.dragHandleProps}>
              <div className="flex h-5 items-center">
                <input
                  id="comments"
                  aria-describedby="comments-description"
                  name="comments"
                  type="checkbox"
                  disabled={!!scheme.archivedAt || disabled}
                  className="h-4 w-4 rounded border-gray-300 text-rose-600 focus:ring-rose-500"
                  onChange={(event) => {
                    return updateStep("completed", event.target.checked);
                  }}
                  checked={step.completed}
                />
              </div>
              <div className="ml-3 text-sm w-full">
                {editGoal || mouseOverControls ? (
                  <div className="grow">
                    <textarea
                      rows={2}
                      name="goal"
                      id="goal"
                      autoFocus
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-rose-500 focus:ring-rose-500 sm:text-sm p-2"
                      placeholder="Edit step"
                      defaultValue={goalText}
                      onChange={(event) => setGoalText(event.target.value)}
                      onBlur={(event) => {
                        setTimeout(() => setEditGoal(false), 10);
                        updateStep("goal", goalText);
                      }}
                      onKeyDown={async (event) => {
                        if (event.key === "Enter") {
                          event.preventDefault();
                          setTimeout(() => setEditGoal(false), 10);
                          updateStep("goal", goalText);
                        }
                      }}
                    />
                  </div>
                ) : (
                  <span className="flex flex-row">
                    {step.assignee && (
                      <span className="mr-2">
                        <User uid={step.assignee} />
                      </span>
                    )}
                    <p
                      id="step-description"
                      className="text-gray-500 text-base"
                      onClick={() => {
                        if (!scheme.archivedAt && !disabled) {
                          setShowControls(true);
                          setEditGoal(true);
                        }
                      }}
                    >
                      {step.completed ? (
                        <s>{step.goal || "Edit step..."}</s>
                      ) : (
                        step.goal || "Edit step..."
                      )}
                    </p>
                  </span>
                )}
              </div>
            </div>
            {(editGoal || mouseOverControls) && (
              <div className="relative py-1">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-300" />
                </div>
                <div className="relative flex justify-center">
                  <span
                    className="isolate inline-flex -space-x-px rounded-md shadow-sm"
                    onMouseEnter={() => {
                      if (editGoal) {
                        setMouseOverControls(true);
                      }
                    }}
                    onMouseLeave={() => setMouseOverControls(false)}
                  >
                    <button
                      type="button"
                      disabled={step.steps === "loading" || disabled}
                      onClick={async () => {
                        const newId = v4();
                        updateStep(`steps.${newId}`, {
                          id: newId,
                          goal: "",
                          completed: false,
                          priority: steps
                            ? steps[steps.length - 1].priority + 1
                            : 0,
                        });
                        // setEditGoal(false);
                        // setMouseOverControls(false);
                      }}
                      className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                    >
                      <span className="sr-only">Add</span>
                      <PlusCircleIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    <button
                      type="button"
                      disabled={step.steps === "loading" || disabled}
                      onClick={async () => {
                        await updateStep(`steps`, "loading");
                        const steps = (await convertGoalToSteps({
                          goal: scheme.goal,
                          existingSteps: scheme.steps,
                          stepToBreak: step.id,
                          stepGoal: goalText,
                        })) as { data: string[] };
                        updateStep(
                          "steps",
                          steps.data
                            .map((stepText, index) => {
                              const newId = v4();
                              return {
                                [newId]: {
                                  id: newId,
                                  goal: stepText,
                                  completed: false,
                                  priority: index,
                                },
                              };
                            })
                            .reduce((a, b) => ({ ...a, ...b }), {})
                        );
                        setEditGoal(false);
                        setMouseOverControls(false);
                      }}
                      className="relative inline-flex items-center border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                    >
                      <span className="sr-only">Generate</span>
                      <SparklesIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                    {/* <button
                      type="button"
                      disabled={disabled}
                      onClick={() => {
                        setMouseOverControls(false);
                        deleteStep();
                      }}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                    >
                      <span className="sr-only">Assign</span>
                      <UserPlusIcon className="h-5 w-5" aria-hidden="true" />
                    </button> */}
                    <Assignee
                      options={[scheme.owner, ...(scheme.members || [])]}
                      onClick={() => setEditGoal(true)}
                      onChange={(selected: string) => {
                        setEditGoal(false);
                        setMouseOverControls(false);
                        updateStep("assignee", selected);
                      }}
                      selected={step.assignee}
                    />
                    <button
                      type="button"
                      disabled={disabled}
                      onClick={() => {
                        setMouseOverControls(false);
                        deleteStep();
                      }}
                      className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-3 py-2 text-sm font-medium text-gray-400 hover:bg-gray-50 focus:z-10 focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500"
                    >
                      <span className="sr-only">Delete</span>
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </span>
                </div>
              </div>
            )}
            {loading ? (
              <Spinner />
            ) : (
              steps && (
                <Steps
                  parentId={step.id}
                  scheme={scheme}
                  updateStep={updateStep}
                  steps={steps}
                />
              )
            )}
          </div>
        )}
      </Draggable>
    </>
  );
}

interface AssigneeProps {
  options: string[];
  onChange: any;
  selected?: string;
  onClick?: any;
}
function Assignee({ options, onChange, selected, onClick }: AssigneeProps) {
  return (
    <Listbox value={selected} onChange={onChange}>
      {({ open }) => (
        <>
          <div className="relative">
            <Listbox.Button
              className={`relative w-20 cursor-default border border-gray-300 bg-white ${
                selected ? "py-1" : "py-2"
              } pl-3 pr-10 text-left shadow-sm focus:border-rose-500 focus:outline-none focus:ring-1 focus:ring-rose-500 sm:text-sm`}
            >
              {selected ? (
                <User uid={selected} />
              ) : (
                <span className="text-gray-500">
                  <UserIcon className="h-5 w-5" />
                </span>
              )}
              <span className="pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2">
                <ChevronUpDownIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </span>
            </Listbox.Button>

            <Transition
              show={open}
              leave="transition ease-in duration-100"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options className="absolute -ml-12 z-10 max-h-56 w-48 overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                <Listbox.Option
                  className={({ active }) =>
                    classNames(
                      active ? "text-white bg-rose-600" : "text-gray-900",
                      "relative cursor-default select-none py-2 pl-3 pr-9"
                    )
                  }
                  value={deleteField()}
                >
                  {({ selected, active }) => (
                    <>
                      No one
                      {selected ? (
                        <span
                          className={classNames(
                            active ? "text-white" : "text-rose-600",
                            "absolute inset-y-0 right-0 flex items-center pr-4"
                          )}
                        >
                          <CheckIcon className="h-5 w-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
                {options.map((uid) => (
                  <Listbox.Option
                    key={uid}
                    className={({ active }) =>
                      classNames(
                        active ? "text-white bg-rose-600" : "text-gray-900",
                        "relative cursor-default select-none py-1 pl-2 pr-9"
                      )
                    }
                    value={uid}
                  >
                    {({ selected, active }) => (
                      <>
                        <User uid={uid} showName />

                        {selected ? (
                          <span
                            className={classNames(
                              active ? "text-white" : "text-rose-600",
                              "absolute inset-y-0 right-0 flex items-center pr-4"
                            )}
                          >
                            <CheckIcon className="h-5 w-5" aria-hidden="true" />
                          </span>
                        ) : null}
                      </>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </div>
        </>
      )}
    </Listbox>
  );
}

const people = [
  {
    id: 1,
    name: "Wade Cooper",
    avatar:
      "https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 2,
    name: "Arlene Mccoy",
    avatar:
      "https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 3,
    name: "Devon Webb",
    avatar:
      "https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80",
  },
  {
    id: 4,
    name: "Tom Cook",
    avatar:
      "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 5,
    name: "Tanya Fox",
    avatar:
      "https://images.unsplash.com/photo-1494790108377-be9c29b29330?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 6,
    name: "Hellen Schmidt",
    avatar:
      "https://images.unsplash.com/photo-1487412720507-e7ab37603c6f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 7,
    name: "Caroline Schultz",
    avatar:
      "https://images.unsplash.com/photo-1568409938619-12e139227838?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 8,
    name: "Mason Heaney",
    avatar:
      "https://images.unsplash.com/photo-1531427186611-ecfd6d936c79?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 9,
    name: "Claudie Smitham",
    avatar:
      "https://images.unsplash.com/photo-1584486520270-19eca1efcce5?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
  {
    id: 10,
    name: "Emil Schaefer",
    avatar:
      "https://images.unsplash.com/photo-1561505457-3bcad021f8ee?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80",
  },
];

function classNames(...classes: any) {
  return classes.filter(Boolean).join(" ");
}

const reorder = (list: any[], startIndex: number, endIndex: number) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
