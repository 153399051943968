import { PlusIcon } from "@heroicons/react/20/solid";
import { collection, orderBy, query, where } from "firebase/firestore";
import { useFirestore, useFirestoreCollectionData, useUser } from "reactfire";
import { Button } from "../components/Button";
import { Spinner } from "../components/Spinner";
import { SchemeCard } from "./SchemeCard";
import { SchemeData } from "./SchemesList";

export function ArchivedList() {
  const firestore = useFirestore();
  const user = useUser();

  const schemesCollection = collection(firestore, "schemes");
  const schemesQuery = query(
    schemesCollection,
    where("owner", "==", user.data?.uid),
    where("archivedAt", "!=", null),
    orderBy("archivedAt", "desc")
  );

  const schemes = useFirestoreCollectionData<SchemeData>(schemesQuery as any, {
    idField: "id",
  });

  if (user.status === "loading") {
    return <Spinner />;
  }

  if (schemes.status === "loading") {
    return <Spinner />;
  }

  return (
    <ul role="list" className="space-y-3">
      {schemes.data.length > 0 ? (
        schemes.data.map((scheme) => (
          <li
            key={scheme.id}
            className="overflow-hidden bg-white px-4 py-4 shadow sm:rounded-md sm:px-6"
          >
            <SchemeCard scheme={scheme} />
          </li>
        ))
      ) : (
        <div className="text-center mb-6">
          <svg
            className="mx-auto h-12 w-12 text-gray-400"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            aria-hidden="true"
          >
            <path
              vectorEffect="non-scaling-stroke"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"
            />
          </svg>
          <h3 className="mt-2 text-sm font-medium text-gray-900">
            No archived lists
          </h3>
          <p className="mt-1 text-sm text-gray-500">
            Archived lists will show up here.
          </p>
        </div>
      )}
    </ul>
  );
}
