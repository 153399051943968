import { User } from "firebase/auth";
import { useFirestore, useUser } from "reactfire";
import { Button } from "../components/Button";
import { SchemeData, SchemesList } from "../scheme/SchemesList";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";

export function useCreateScheme() {
  const currentUser = useUser<User>();
  const firestore = useFirestore();

  if (currentUser.status === "loading") {
    return (
      scheme: Omit<
        SchemeData,
        "id" | "owner" | "createdAt" | "goal" | "archivedAt"
      >
    ) => {};
  }

  return async (
    scheme: Omit<
      SchemeData,
      "id" | "owner" | "createdAt" | "goal" | "archivedAt"
    >
  ) => {
    return await addDoc(collection(firestore, "schemes"), {
      ...scheme,
      owner: currentUser.data?.uid,
      createdAt: serverTimestamp(),
      archivedAt: null,
      goal: "",
    });
  };
}

export const ListsPage = () => {
  return (
    <>
      <SchemesList />
    </>
  );
};
