import { CallToAction } from "./components/CallToAction";
import { Faqs } from "./components/Faqs";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Hero } from "./components/Hero";
import { Pricing } from "./components/Pricing";
import { PrimaryFeatures } from "./components/PrimaryFeatures";
import { Reviews } from "./components/Reviews";
import { SecondaryFeatures } from "./components/SecondaryFeatures";

export default function LandingPage() {
  return (
    <>
      <Header />
      <main className="mb-auto">
        {/* <Hero /> */}
        <PrimaryFeatures />
        {/* <SecondaryFeatures /> */}
        <CallToAction />
        {/* <Reviews /> */}
        {/* <Pricing /> */}
        {/* <Faqs /> */}
      </main>
      {/* <Footer /> */}
      <footer className="bg-white shadow-inner">
        <div className="mx-auto max-w-7xl overflow-hidden py-12 px-4 sm:px-6 lg:px-8">
          <nav
            className="-mx-5 -my-2 flex flex-wrap justify-center"
            aria-label="Footer"
          >
            <div className="px-5 py-2">
              <a
                href="mailto:support@autodo.to"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Contact
              </a>
            </div>
            <div className="px-5 py-2">
              <a
                href="/app/tos"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Terms of Service
              </a>
            </div>
            <div className="px-5 py-2">
              <a
                href="/app/privacy"
                className="text-base text-gray-500 hover:text-gray-900"
              >
                Privacy Policy
              </a>
            </div>
          </nav>
          {/* <div className="mt-8 flex justify-center space-x-6">
              {navigation.social.map((item) => (
                <a
                  key={item.name}
                  href={item.href}
                  className="text-gray-400 hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="h-6 w-6" aria-hidden="true" />
                </a>
              ))}
            </div> */}
        </div>
      </footer>
    </>
  );
}
